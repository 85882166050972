.destination {
  margin-left: $container-mobile;
  margin-right: $container-mobile;

  @include lg-min {
    margin-left: $container-desktop;
    margin-right: $container-desktop;
  }

  &__details-wrapper,
  &__image-wrapper,
  &__information-wrapper {
    margin-bottom: 1rem;

    @include lg-min {
      margin-bottom: 2rem;
    }
  }

  &__details-wrapper {
    display: flex;
    justify-content: space-between;

    &--items {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      @include lg-min {
        flex-direction: row;
        gap: 2rem;
      }

      span {
        display: flex;
        align-items: center;
        gap: 0.625rem;

        p {
          margin-bottom: 0;
        }
      }
    }
  }

  &__image-wrapper {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    @include lg-min {
      grid-template-columns: 2fr 1fr 1fr;
    }

    &--image {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &:first-of-type {
        grid-column: 1 / span 4;

        @include lg-min {
          grid-row: 1 / span 2;
          grid-column: 1;
        }
      }

      img {
        border-radius: $border-radius;
        filter: $drop-shadow;
        width: 100%;
      }
    }
  }

  &__information-wrapper {
    display: flex;
    flex-direction: column;

    @include lg-min {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      gap: 1rem;
    }

    &--tabs {
      grid-column: 1 / span 2;

      &__items {
        display: flex;
        gap: 2rem;
        border-bottom: 1px solid $gray;
        margin-bottom: 1rem;

        h6 {
          margin: 0;
          cursor: pointer;

          &.active {
            color: $primary;
          }
        }
      }
    }

    &--card {
      filter: $drop-shadow;
      border: 1px solid $gray;
      border-radius: $border-radius;
      padding: 1.25rem;
      background-color: $white;

      &__items {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        &:last-of-type {
          margin-bottom: 0;
        }

        p {
          margin: 0;

          &:first-child {
            font-weight: bold;
          }
        }
      }
    }
  }
}
