.layout {
  margin-top: 2rem;
}

.header-button {
  position: relative;
  padding: 0 20px;

  @include lg-min {
    padding: 0;
  }

  &__dropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: $border-radius;
    filter: $drop-shadow;
    top: 3rem;
    border: 1px solid $gray;

    &--item {
      padding: 0.625rem !important;

      &:not(:last-of-type) {
        border-bottom: 1px solid $gray;
      }
    }
  }
}
