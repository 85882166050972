.header {
  height: 40px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $primary;
  position: relative;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4));

  @include lg-min {
    padding: 20px 90px;
  }

  &__logo-wrapper {
    position: relative;
    height: inherit;

    &--logo {
      max-height: 100%;
    }
  }

  &__navigation {
    &--menu {
      border: 1px solid $black;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      aspect-ratio: 1/1;
      cursor: pointer;

      @include lg-min {
        display: none;
      }
    }

    &--items {
      display: none;

      @include lg-max {
        &.menu-opened {
          display: flex;
          flex-direction: column;
          position: absolute;
          width: 100%;
          left: 0;
          background-color: $primary;
          padding: 20px 0;
          gap: 10px;
        }
      }

      @include lg-min {
        display: flex;
        align-items: center;
        gap: 30px;
      }

      a {
        color: $text-color;
        text-decoration: none;
        font-weight: bold;
        padding: 0 20px;

        @include lg-min {
          padding: 0;
        }

        &:hover {
          color: var(--secondary);
        }
      }
    }
  }
}
