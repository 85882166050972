$primary: #F7CE3E;
$secondary: #0A1612;
$tertiary: #1A2930;
$quaternary: #4169E1;

$lightgray: #F1EFEF;
$gray: #C5C1C0;
$black: #000000;
$white: #FFFFFF;

$text-color: $secondary;

:export {
  primaryColor: $primary;
  secondaryColor: $secondary;
  tertiaryColor: $tertiary;
  quaternaryColor: $quaternary;
  grayColor: $gray;
  blackColor: $black;
  whiteColor: $white;
}
