.login-page {
  background-color: $primary;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &__form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: $white;
    border-radius: $border-radius;
    padding: 40px 2rem;
    filter: $drop-shadow;
    width: 70%;

    @include md-min {
      width: 50%;
    }

    @include xl-min {
      width: 25%;
    }

    &--logo {
      text-align: center;
    }

    &--back {
      cursor: pointer;
    }

    &--buttons {
      display: flex;
      justify-content: center;
    }

    &--divider {
      width: 100%;
      text-align: center;
      border-bottom: 1px solid $text-color;
      line-height: 0.1em;
      margin: 20px 0;
      font-weight: bold;

      span {
        background: $white;
        padding: 0 10px;
      }
    }

    &--switch-form {
      margin-bottom: 0;

      span {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
