.links-input {
  @include md-min {
    grid-column: 1 / 3;
  }

  @include lg-min {
    grid-column: 1 / 4;
  }

  &__input-fields {
    background-color: $lightgray;
    padding: 1rem;
    display: grid;
    gap: 1rem;
    border-radius: $border-radius;

    &--wrapper {
      display: grid;
      gap: 1rem;
      grid-template-columns: 2rem 1fr;

      &__remove {
        @extend .btn;
        padding: 0;
      }

      &__inputs {
        grid-template-columns: 1fr 1fr;
        display: grid;
        gap: 1rem;
      }
    }
  }

  &__add-item {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }
}
