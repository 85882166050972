h1, h2, h3, h4, h5, h6, p {
  margin-top: 0;
  color: $text-color;
  margin-bottom: 1rem;
}

.text-small {
  font-size: 14px;
}

h3 {
  font-size: 48px;
}

h5 {
  font-size: 24px;
}

h6 {
  font-size: 20px;
}
