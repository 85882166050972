.btn,
.button {
  all: unset;
  border-radius: $border-radius;
  padding: 10px 20px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: fit-content;

  &-primary {
    background-color: $primary;
    color: $text-color;

    &:hover {
      background-color: darken($primary, 15%);
    }
  }

  &-secondary {
    background-color: $secondary;
    color: $white;

    &:hover {
      background-color: darken($secondary, 15%);
    }
  }
}
