.create-destination {
  margin-left: $container-mobile;
  margin-right: $container-mobile;

  @include lg-min {
    margin-left: $container-desktop;
    margin-right: $container-desktop;
  }

  &__map {
    margin-bottom: 2.5rem;
  }

  &__form {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;

    @include md-min {
      grid-template-columns: 1fr 1fr;
    }

    @include lg-min {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &--description {

      @include md-min {
        grid-column: 1 / 3;
      }

      @include lg-min {
        grid-column: 1 / 4;
      }
    }
  }

  &__submit {
    margin-top: 1rem;
  }
}
