.card-wrapper {
  display: grid;
  gap: 1rem;

  @include md-min {
    grid-template-columns: 1fr 1fr;
  }

  @include xl-min {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
