.toggle {

  &__text {
    margin-bottom: 2px;
  }

  &__wrapper {
    position: relative;
    display: inline-block;
    width: 3.375rem;
    height: 1.875rem;

    &--input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .toggle__wrapper--switch {
        background-color: $primary;

        &:before {
          transform: translateX(1.5rem);
        }
      }

      &:focus + .toggle__wrapper--switch {
        box-shadow: 0 0 1px $primary;
      }
    }

    &--switch {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $secondary;
      transition: 0.4s;
      border-radius: 15px;

      &::before {
        position: absolute;
        content: "";
        height: 1.5rem;
        width: 1.5rem;
        left: 3px;
        bottom: 3px;
        background-color: $white;
        transition: 0.4s;
        border-radius: 50%;
      }
    }
  }
}
