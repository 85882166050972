.form-textarea {
  display: flex;
  flex-direction: column;

  &__label {
    @extend .text-small;
    margin-bottom: 2px;
  }

  &__textarea {
    border-radius: $border-radius-small;
    border: 1px solid $gray;
    font-size: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
