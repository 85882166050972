/* ----------------------------------------------------------------------------
 * Breakpoints
 * ------------------------------------------------------------------------- */

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;
$screen-md-max: 767px;

// Tablets and small desktops
$screen-lg-min: 992px;
$screen-lg-max: 991px;

// Large tablets and desktops
$screen-xl-min: 1200px;
$screen-xl-max: 1199px;

// HD/4K desktops
$screen-xxl-min: 1600px;

// Small devices
@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
}

// Medium devices
@mixin md-min {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
}

@mixin md-max {
    @media (max-width: #{$screen-md-max}) {
        @content;
    }
}

// Large devices
@mixin lg-min {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
}

@mixin lg-max {
    @media (max-width: #{$screen-lg-max}) {
        @content;
    }
}

// Extra large devices
@mixin xl-min {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
}
@mixin xl-max {
    @media (max-width: #{$screen-xl-max}) {
        @content;
    }
}

// XX large devices
@mixin xxl-min {
    @media (min-width: #{$screen-xxl-min}) {
        @content;
    }
}
