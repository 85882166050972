.destination-card {
  aspect-ratio: 16 / 9;
  padding: 1.25rem;
  text-decoration: none;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  filter: $drop-shadow;

  &:hover,
  &:focus {

    .destination-card__category-wrapper--category,
    .destination-card__title {
      text-decoration: underline;
    }
  }

  &__category-wrapper {
    background-color: $primary;
    width: fit-content;
    padding: 0.625rem;
    border-radius: $border-radius;

    &--category {
      margin: 0;
      font-weight: bold;
    }
  }

  &__title {
    margin: 0;
    color: $white;
  }
}
