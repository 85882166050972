.heading-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 1rem;

  @include md-min {
    flex-direction: row;
    align-items: center;
  }

  &__title {
    margin: 0;
  }

  &__link {
    display: flex;
    align-items: center;
    color: $quaternary;
    text-decoration: none;
    font-weight: bold;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
